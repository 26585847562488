(function() {
  var app = angular.module('priorWeb');

  app.factory('CategoryViewModel', [
    function() {
      function CategoryViewModel(category) {
        var name = category.getName();
        this.open = false;

        this.getName = function() {
          return name;
        };

        this.isOpen = function() {
          return this.open;
        };

        this.setIsOpen = function(newValue) {
          this.open = newValue;
        };
      }

      CategoryViewModel.build = function(category) {
        return new CategoryViewModel(category);
      };

      return CategoryViewModel;
    }
  ]);
})();
